import { useContext, useEffect, useState } from "react";
import { Container, TextField, IconButton, InputAdornment, Box, Button, Typography, Backdrop, CircularProgress, Grid2, CardMedia, CardContent, Chip, Card, Skeleton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from '@mui/material';
import AppAppBar from "../components/AppAppBar";
import Footer from "../components/Footer";
import { Alert, Snackbar } from "@mui/material";
import { ThemeContext } from "../ThemeContext";
import { onValue, ref } from "firebase/database";
import { database } from "../firebase";
import supabase from "../supabase";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";



export default function Home() {
  const items = [
    {
      "image": "https://i.ytimg.com/vi/Q_1M2JaijjQ/maxresdefault.jpg",
      "title": "Introduction To Big O Notation & Complexity Analysis",
      "available": true,
      "page": "/big-o-notation"
    },
    {
      "image": "https://miro.medium.com/v2/resize:fit:1400/1*J38nYZU7gzu-4lQmtjlSUw.jpeg",
      "title": "Exploring And Building Data Structures",
      "available": true,
      "page": "/data-structures"
    },
    {
      "image": "https://qsstudy.com/wp-content/uploads/2019/01/Algorithm-1.jpg",
      "title": "Exploring Different Algorithms",
      "available": true,
      "page": "/algorithms"
    }
  ];
  
  const { darkMode } = useContext(ThemeContext);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [problemId, setProblemId] = useState('');
  const navigate = useNavigate();
  const [dailyProblemId, setDailyProblemId] = useState(null);
  const [dailyProblemData, setDailyProblemData] = useState(null);
  const [error, setError] = useState('');

 

  useEffect(() => {
    const fetchProblemData = async () => {
      // Fetch the daily problem ID
      const db_ref = ref(database, "daily_problem_id");
      onValue(db_ref, async (snapshot) => {
        const data = snapshot.val();
        setDailyProblemId(data);

        if (data) {
          const { data: problemData, error: fetchError } = await supabase
            .from("problems")
            .select("problem_id, title, difficulty")
            .eq("problem_id", data)
            .single();

          if (fetchError) {
            console.error("Error fetching problem data:", fetchError);
          } else {
            setDailyProblemData(problemData);
          }
        }
        

      });
    };

    // Only fetch data if dailyProblemId is null (first load)
    if (dailyProblemId === null) {
      fetchProblemData();
    }
  }, [dailyProblemId]);
  
  const handleClose = () => {
      setError('');
  };

  const goToSearch = () => {
    const trimmedProblemId = problemId.trim();
    if (!trimmedProblemId) {
      setError('Problem ID cannot be empty')
      return;
    }

    navigate(`/problems/${trimmedProblemId}`);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: darkMode ? 'grey.900' : 'white' }}>
      {/* AppBar at the top */}


 
      {/* <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={!dailyProblemData}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

      <AppAppBar/>

      {/* Centering container */}
      <Container sx={{flex: 1}}>
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: isMobile ? "85%" : "100%",
              maxWidth: "md",
              padding: 3,
              borderRadius: "12px",
              flexDirection: 'column',
              border: '2px solid', 
              borderColor: darkMode ? "#ccc" : "#a6a59f"
            }}
          >
           {/* <Box
              sx={{
                mb: 2,
                border: "1px solid #ccc",
                borderColor: darkMode ? "#555" : "#ccc",
                borderRadius: '5px',
                padding: 2,
                width: "100%",
                position: 'relative',
                '&:hover': {
                  borderColor: darkMode ? "#777" : "#999",
                },
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: '-12px',
                  left: '12px',
                  fontSize: '12px',
                  color: darkMode ? "#999" : "#666",
                  backgroundColor: darkMode ? "#333" : "#fff",
                  transition: 'all 0.2s ease',
                  padding: '0 4px',
                }}
              >
                LeetCode Daily
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {dailyProblemData ? (
                  <>
                    <Box
                      sx={{
                        width: isMobile ? '12px' : '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor:
                          dailyProblemData.difficulty === 'Easy'
                            ? '#28a745'
                            : dailyProblemData.difficulty === 'Medium'
                            ? '#ffc107'
                            : '#dc3545',
                      }}
                    />
                    <Typography
                      sx={{
                        ml: 1.5,
                        fontSize: '12px',
                        flexGrow: 1,
                        color: darkMode ? '#e0e0e0' : '#333',
                      }}
                    >
                      {dailyProblemData.problem_id + ". " + dailyProblemData.title}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Skeleton
                      variant="circular"
                      width={isMobile ? '12px' : '10px'}
                      height="10px"
                    />
                    <Skeleton variant="text" width="60%" sx={{ ml: 1.5, fontSize: '12px' }} />
                  </>
                )}
                {dailyProblemData &&
                  <IconButton onClick={() => dailyProblemData && navigate(`/problems/${dailyProblemId}`)}>
                    <ArrowForward />
                  </IconButton>
                }
              </Box>
            </Box> */}

            

            <TextField
              fullWidth
              variant="outlined"
              placeholder="leetcode problem id"
              value={problemId}
              onChange={(e) => setProblemId(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  backgroundColor: darkMode ? "#2a2a2a" : "#fff",
                  color: darkMode ? "#fff" : "#000",
                  "& fieldset": {
                    borderColor: darkMode ? "#555" : "#ccc",
                  },
                  "&:hover fieldset": {
                    borderColor: darkMode ? "#888" : "#888",
                  },
                },
                input: {
                  color: darkMode ? "#fff" : "#000",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isMobile ? (
                      <IconButton
                        sx={{
                          backgroundColor: darkMode ? '#082680' : '#1e2459',
                          color: "#fff",
                          borderRadius: "8px",
                          width: "48px",
                          height: "48px",
                          '&:hover': {
                            backgroundColor: darkMode ? "#4a5270" : "#003d8e",
                          },
                        }}
                        onClick={() => goToSearch()}
                      >
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: darkMode ? '#082680' : '#1e2459',
                          color: "#fff",
                          borderRadius: "12px",
                          padding: "8px 16px",
                          '&:hover': {
                            backgroundColor: darkMode ? "#4a5270" : "#003d8e",
                          },
                        }}
                        onClick={() => goToSearch()}
                      >
                        Search
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Grid2 container spacing={3} mt={3} mb={3} sx={{justifyContent: "center"}}>
            {items.map((item, index) => (
              <Grid2 item xs={12} sm={6} md={4} key={index}>
              <Card 
                sx= {{ 
                  maxWidth: 345, 
                  height: 250, 
                  borderRadius: 3, 
                  position: 'relative',
                  backgroundColor: darkMode
                  ? 'rgba(30, 30, 30, 0.8)' // Matching dark mode background
                  : 'rgba(255, 255, 255, 0.2)', // Light mode background
                  boxShadow: darkMode
                  ? '0 8px 16px rgba(0, 0, 0, 0.5)' // Darker shadow for dark mode
                  : '0 8px 16px rgba(0, 0, 0, 0.1)', // Softer shadow for light mode
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: darkMode ? '0 12px 24px rgba(0, 0, 0, 0.7)' : '0 12px 24px rgba(0, 0, 0, 0.2)', // Elevated shadow on hover
                  },
                  cursor: 'pointer'
                }}
                onClick={() => item.available ? navigate(item.page) : ''}
                >
                <Box sx={{ position: 'relative' }}>
                  <CardMedia
                    component="img"
                    height="150"
                    image={item.image}
                    alt={item.title}
                    sx={{ objectFit: 'cover' }}
                  />
                  {!item.available && (
                    <Chip
                      label="coming soon..."
                      color="error"
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: '#857308',
                        borderRadius: 2
                      }}
                    />
                  )}
                </Box>
                <CardContent>
                <Typography 
                  component="div" 
                  sx={{ 
                    fontFamily: 'Fredoka One, sans-serif',
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: darkMode ? '#e0e0e0' : '#333', // Dark text for readability
                    textAlign: 'center',
                  }}
                  >
                  {item.title}
                </Typography>

                </CardContent>
              </Card>

              </Grid2>
            ))}
        </Grid2>

        </Box>
      

      </Container>
      {error && 
         <Snackbar open={error !==''} autoHideDuration={6000} onClose={handleClose}>
          <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: '100%' }}
          >
              {error}
          </Alert>
        </Snackbar>
      }
      <Footer/>
    </Box>
  );
}
