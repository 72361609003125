import { Box, Card, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext } from "react";
import AppAppBar from "../components/AppAppBar";
import { ThemeContext } from "../ThemeContext";
import Footer from '../components/Footer';

function Algorithms(){
    const { darkMode } = useContext(ThemeContext);
    const navigate = useNavigate();

    const items = [
        { title: 'Brute Force', path: 'brute-force' },
        { title: 'Linear Search', path: 'linear-search' },
        { title: 'Two Pointers', path: 'two-pointers' },
        { title: 'Sliding Window', path: 'sliding-window' },
        { title: 'Binary Search', path: 'binary-search' },
        { title: 'Kadane\'s Algorithm', path: 'kadanes-algorithm' },
        { title: 'Floyd\'s Cycle Detection Algorithm', path: 'floyds-cycle-detection' },
        { title: 'Boyer-Moore Majority Voting Algorithm', path: 'boyer-moore-majority-voting' },
    ];

    return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            bgcolor: darkMode ? 'grey.900' : 'white',
          }}
        >
            <AppAppBar />
           
            <Box sx={{ mt: 10, flexGrow: 1, p:2 }}>
                <Box
                    sx={{
                        maxWidth: 'md',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mx: 'auto', // Centers the container horizontally
                    }}
                >
                    {items.map((item, index) => (
                        <Card
                            key={index}
                            sx={{
                                textDecoration: 'none',
                                backgroundColor: darkMode ? 'rgba(30, 30, 30, 0.8)' : 'rgba(255, 255, 255, 0.9)',
                                color: darkMode ? '#e0e0e0' : '#333',
                                boxShadow: 3,
                                width: '100%',
                                display: 'flex',   
                                alignItems: 'center',
                                cursor: 'pointer',
                                height: '50px',
                                '&:hover': {
                                    boxShadow: darkMode ? '0 12px 24px rgba(0, 0, 0, 0.7)' : '0 12px 24px rgba(0, 0, 0, 0.2)', // Elevated shadow on hover
                                },
                            }}
                            onClick={() => navigate(`/algorithms/${item.path}`)}
                        >
                         <Typography variant="body2" sx={{ p: 2, fontFamily: 'Roboto Slab, serif' }}>
                            {item.title}
                        </Typography>
                       </Card>
                    ))}
                </Box>

            </Box>
            <Footer/>
        </Box>
    );
}

export default Algorithms;