import React, { useContext, useState } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent,
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  IconButton,
  Card,
  useTheme,
  useMediaQuery,
  Snackbar,
} from '@mui/material';
import { ContentCopy, Close } from '@mui/icons-material';
import { ThemeContext } from '../ThemeContext';

const DonationDialog = ({ onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
  const { darkMode } = useContext(ThemeContext);

  const [selectedWallet, setSelectedWallet] = useState('BITCOIN');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const wallets = {
    'BITCOIN': '1NzJBRApz43agraEDg1r91pv1MnAyXHgAV',
    '(USDT) ERC-20': '0x704f4fca920d4a351edcc5da0c1f44e6ed0954aa',
    '(USDT) TRC-20': 'TSDX7LvriMDaoyBzt7Bjhv6FcfcUwBCWms'
  };

  const handleChange = (event) => {
    setSelectedWallet(event.target.value);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(wallets[selectedWallet])
      .then(() => {
        setSnackbarOpen(true); // Show confirmation when copy succeeds
      })
      .catch(() => {
        // Optionally handle errors
        console.error('Failed to copy text.');
      });
  };

  return (
    <>
      <MuiDialog
        open
        fullWidth={true}
        maxWidth="xs"
        PaperProps={{
          style: {
            borderRadius: '10px',
            textAlign: 'center',
            margin: isMobile ? '0 16px' : '20px',
            backgroundColor: theme.palette.background.default,
            position: 'relative',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              ml: 2,
              mt: 1,
              mb: 2,
              flexGrow: 1,
              textAlign: 'center',
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            crypto donations using any of the options below
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              color: theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </Box>

        <DialogContent>
          <Select
            value={selectedWallet}
            onChange={handleChange}
            fullWidth
            sx={{
              mb: 2,
              backgroundColor: darkMode
                ? 'rgba(30, 30, 30, 0.8)'
                : 'rgba(255, 255, 255, 0.9)',
              borderRadius: '8px',
            }}
          >
            {Object.keys(wallets).map((wallet) => (
              <MenuItem key={wallet} value={wallet}>
                {wallet}
              </MenuItem>
            ))}
          </Select>

          <Card
            sx={{
              display: 'flex',
              flexDirection: 'row',
              p: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              backgroundColor: darkMode
                ? 'rgba(30, 30, 30, 0.8)'
                : 'rgba(255, 255, 255, 0.9)',
              borderRadius: '8px',
              boxShadow: theme.shadows[1],
            }}
          >
            <Typography
              sx={{
                flexGrow: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                color: theme.palette.text.primary,
              }}
            >
              {wallets[selectedWallet]}
            </Typography>

            <IconButton onClick={handleCopy}>
              <ContentCopy />
            </IconButton>
          </Card>

          <Typography
            variant="body1"
            sx={{
              mt: 2,
              fontSize: '15px',
              lineHeight: 1.5,
              fontFamily: "'Times New Roman', Times, serif",
              color: darkMode ? "#fff" : "#000",
            }}
          >
            Thank you for your donation! 😊
          </Typography>
        </DialogContent>
      </MuiDialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message="Address copied to clipboard!"
      />
    </>
  );
};

export default DonationDialog;
